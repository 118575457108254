<template lang="pug">
  form 
    section.container
      aside
        #filter
          .containerFilter 
            .reset
              el-button(v-if="reset" @click="filterOrders(true)") Reset
            ul
              li
                h4 Cliente
                el-select(
                  filterable clearable multiple
                  v-model="filtersOrdersList.clients"
                  @change="filterOrders()"
                )
                  el-option(
                    :label="client.name" :value="client.id"
                    v-for="client in clientsList"
                    :key="client.id"
                  ) {{ client.name }}
              li
                h4 Agente
                el-select(
                  filterable clearable
                  v-model="filtersOrdersList.agent"
                  @change="filterOrders()"
                )
                  el-option(
                    :label="agent.name" :value="agent.id"
                    v-for="agent in agentsList"
                    :key="agent.id" v-if="agent.is_available"
                  )
              li
                h4 Stato Completato
                el-select(
                  filterable clearable
                  v-model="filtersOrdersList.completed"
                  @change="filterOrders()"
                )
                  el-option(
                    :label="'Sì'" :value="true"
                  ) Sì
                  el-option(
                    :label="'No'" :value="false"
                  ) No
              li
                h4 Data Ordine
                el-date-picker(
                  v-model="filtersOrdersList.orderDate"
                  type="daterange"
                  range-separator="A"
                  start-placeholder="Data inizio"
                  end-placeholder="Data fine"
                  @change="filterOrders()"
                )
</template>

<script>
import Vue from "vue";
import { mapGetters } from "vuex";

export default Vue.extend({
  name: "FilterOrders",
  data() {
    return {
      // Oggetto che contiene i filtri applicabili agli ordini
      filtersOrdersList: {
        clients: [],
        agent: null,
        completed: null,
        orderDate: null,
      },
      reset: false, // Booleano per il pulsante reset
      categories_option: ["Uomo", "Donna", "Bambino", "Accessori"],
      order_status_option: ["In corso", "Completato", "Annullato"],
    };
  },
  computed: {
    // Usa Vuex per ottenere dati relativi ai clienti, agenti e ordini
    ...mapGetters("clients", ["clientsList"]),
    ...mapGetters("agents", ["agentsList"]),
    ...mapGetters("orders", ["ordersList"]),
  },
  mounted() {
    // Ottieni i dati necessari dal Vuex store all'avvio del componente
    this.$store.dispatch("clients/getClientsList");
    this.$store.dispatch("agents/getAgentsList");
    this.$store.dispatch("orders/getOrdersList");
  },
  watch: {
    // Watch per verificare se i filtri sono attivi e abilitare/disabilitare il pulsante reset
    filtersOrdersList: {
      handler: function (value) {
        let sentinelOrders = false;

        // Controlla se c'è almeno un filtro applicato
        for (const key of Object.keys(this.$data.filtersOrdersList)) {
          if (
            this.$data.filtersOrdersList[key].length ||
            this.$data.filtersOrdersList[key] === true ||
            this.$data.filtersOrdersList[key] === false
          ) {
            sentinelOrders = true;
            break;
          }
        }
        this.$data.reset = sentinelOrders;
      },
      deep: true,
    },
  },
  methods: {
    // Metodo per filtrare gli ordini
    async filterOrders(reset = false) {
      if (reset) {
        // Se reset è true, resetta tutti i filtri
        this.$data.reset = false;
        for (const key of Object.keys(this.$data.filtersOrdersList)) {
          this.$data.filtersOrdersList[key] = null;
        }
        this.$store.dispatch("orders/getOrdersList");
      } else {
        // Applica i filtri sugli ordini
        let f = { ...this.$data.filtersOrdersList };
        for (const key of Object.keys(f)) {
          if (!f[key]) delete f[key];
        }
        // Esegui la chiamata Vuex per ottenere i dati filtrati
        this.$store.dispatch("orders/filterOrders", f);
      }
    },
  },
});
</script>