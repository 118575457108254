<template lang="pug">
  form 
    section.container
      aside
        #filter
          .containerFilter 
            .reset
              el-button(v-if="reset" @click="filterCustomers(true)") Reset
            ul
              li
                h4 Categorie
                el-select(
                  filterable clearable multiple
                  v-model="filtersCustomersList.categories"
                  @change="filterCustomers()"
                )
                  el-option(
                    :label="categories" :value="categories"
                    v-for="categories in categories_option"
                    :key="categories"
                  ) {{ categories }}
              li
                h4 Brand
                el-select(
                  filterable clearable
                  v-model="filtersCustomersList.brand"
                  @change="filterCustomers()"
                )
                  el-option(
                    :label="brand.name" :value="brand.id"
                    v-for="brand in brandsList"
                    :key="brand.id" v-if="brand.is_available"
                  )
              li
                h4 Rating commerciale
                el-select(
                  filterable clearable
                  v-model="filtersCustomersList.commercial_rating"
                  @change="filterCustomers()"
                )
                  el-option(
                    :label="commercial_rating"
                    :value="commercial_rating"
                    v-for="commercial_rating in commercial_rating_option"
                    :key="commercial_rating"
                  ) {{ commercial_rating }}
              li
                h4 Rating finanziario
                el-select(
                  filterable clearable
                  v-model="filtersCustomersList.financial_rating"
                  @change="filterCustomers()"
                )
                  el-option(
                    v-for="financial_rating in financial_rating_option" :key="financial_rating"
                    :label="financial_rating" :value="financial_rating"
                  ) {{ financial_rating }}
              li
                h4 Postcode
                el-select(
                  filterable clearable
                  v-model="filtersCustomersList.postcode"
                  @change="filterCustomers()"
                )
                  el-option(
                    v-for="postcode in postcodes" :key="postcode"
                    :label="postcode" :value="postcode"
                    v-if="postcode.length"
                  )
              li
                h4 Città
                el-select(
                  filterable clearable
                  v-model="filtersCustomersList.city"
                  @change="filterCustomers()"
                )
                  el-option(
                    v-for="city in cities" :key="city"
                    :label="city" :value="city"
                    v-if="city.length"
                  )
              li
                h4 Regione
                el-select(
                  filterable clearable
                  v-model="filtersCustomersList.region"
                  @change="filterCustomers()"
                )
                  el-option(
                    v-for="region in regions" :key="region" 
                    :label="region" :value="region"
                    v-if="region.length"
                  )                
</template>

<script>
import Vue from "vue";

import { mapGetters } from "vuex";

export default Vue.extend({
  name: "filterCustomers",
  data() {
    return {
      seasonsForm: ["", ""],
      cities: [],
      postcodes: [],
      provinces: [],
      regions: [],
      reset: false,
      filtersCustomersList: {},
      categories_option: ["Uomo", "Donna", "Bambino", "Accessori"],
      commercial_rating_option: ["A", "B", "C"],
      financial_rating_option: ["A", "B", "C"]
    };
  },
  computed: {
    ...mapGetters("brands", ["brandsList"]),
    ...mapGetters("clients", ["mapData"])
  },
  mounted() {
    this.$store.dispatch("brands/getBrandsList");
    this.$store.dispatch("clients/getMap", {}).then(() => {
      const maps = this.mapData;
      this.postcodes = new Set(maps.map(x => x.postcode));
      this.cities = new Set(maps.map(x => x.city));
      this.regions = new Set(maps.map(x => x.region));
    });
  },
  watch: {
    filtersCustomersList: {
      handler: function(value) {
        let sentinelCustomers = false;

        for (const key of Object.keys(this.$data.filtersCustomersList)) {
          if (
            this.$data.filtersCustomersList[key].length ||
            this.$data.filtersCustomersList[key].length === undefined
          ) {
            sentinelCustomers = true;
            break;
          }
        }
        this.$data.reset = sentinelCustomers;
      },
      deep: true
    }
  },
  methods: {
    async filterCustomers(reset) {
      if (reset) {
        this.$data.reset = false;
        for (const key of Object.keys(this.$data.filtersCustomersList)) {
          delete this.$data.filtersCustomersList[key];
        }
        this.$store.dispatch("clients/getClientsList");
      }

      let f = { ...this.$data.filtersCustomersList };
      for (const key of Object.keys(f)) {
        if (!f[key]) delete f[key];
      }
      this.$store.dispatch("clients/filterClients", f);
    }
  }
});
</script>
