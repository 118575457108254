
import Vue from "vue";
import Search from "@/components/Search.vue";
import FilterClients from "@/components/FilterCustomers.vue";
import FilterCompanies from "@/components/FilterCompanies.vue";
import FilterOrders from "@/components/FilterOrders.vue";

export default Vue.extend({
  name: "BlueTop",
  props: {
    "action": {
    type: String,
    required: true
  },
    "name": {
      type: String,
      required: true
    },
    "name2": {
      type: String,
      required: false
    },
    "link1": {
      type: String,
      required: true
    },
    "link2": {
      type: String,
      required: false
    },
    "active": {
      type: Number,
      required: true
    },
    "showFilter": {
      type: Boolean,
      default: true 
    },
    showSearch: {
      type: Boolean,
      default: true 
    }
  },
  components: {
    search: Search,
    filter_clients: FilterClients,
    filter_companies: FilterCompanies,
    filter_orders: FilterOrders
  },
  data() {
    return {
      filterClientsVisible: false,
      filterCompaniesVisible: false,
      filterOrdersVisible: false,
      filters: {
        province: [],
        season_year: null,
        client: null,
        brand: []
      }
    };
  },
  methods: {
    filterButton(buttonChoice: number) {
      this.$store.dispatch("brands/getBrandsList");
      this.$store.dispatch("brandtrends/getBrandTrendsList");
      this.$store.dispatch("clients/getMap", this.$data.filters);
      this.$store.dispatch("orders/applyFilters", this.$data.filters);
      if (buttonChoice == 1) {
        this.$data.filterClientsVisible = true;
      } else if (buttonChoice == 2) {
        this.$data.filterCompaniesVisible = true;
      } else if (buttonChoice == 3) {
        this.$data.filterOrdersVisible = true;
      }
    
    },
    handleAction() {
      window.location.href = this.action;
    }
  }
});
