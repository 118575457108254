import { ActionContext } from "vuex";
import { RootState } from "../state";

interface Filters {
  client?: number;
  date?: string;
  commercial?: number;
  completed?: boolean | null;
}

interface OrdersData {
  client: number | null;
  date: string | null;
  commercial: number | null;
  completed: boolean | null;
}

interface Order {
  id: number;
  cod: string;
  client: {
    id: number;
    name: string;
  };
  date: string;
  internal_number?: string;
  company_line?: {
    id: number;
    name: string;
  };
  brand: {
    id: number;
    name: string;
  };
  brand_trend: {
    id: number;
    name: string;
  };
  season: string;
  year: number;
  commercial: {
    id: number;
    name: string;
  };
  payment_method: {
    id: number;
    name: string;
  };
  commercial_condition?: {
    id: number;
    name: string;
  };
  actual_delivery: string;
  man_total: number;
  woman_total: number;
  number_of_pieces: number;
  total_sum: number;
  commission_agency: number;
  commission_commercial: number;
  total_sum_commission_agency: number;
  total_sum_commission_commercial: number;
  total_sum_net: number;
  total_sum_net_commissions: number;
  vat: number;
  gross_import: number;
  delivered_import: number;
  gross_delivered_import: number;
  gross_residual_import: number;
  note?: string;
  is_archived: boolean;
  is_completed: boolean;
}

export interface OrderState {
  orders: any;
  paymentMethods: any;
  seasons: any;
  paymentsVersions: any;
  versions: any;
  count: number;
  filteredOrdersList: Order[];
}

type OrderContext = ActionContext<OrderState, RootState>;

const orders = {
  namespaced: true,
  state: {
    orders: {},
    paymentMethods: [],
    seasons: [],
    paymentsVersions: [],
    versions: [],
    count: 0,
    filteredOrdersList: [],
  },
  getters: {
    ordersList: (state: OrderState): any => {
      return state.orders.results;
    },
    ordersCount: (state: OrderState): number => {
      return state.orders.count;
    },
    paymentMethods: (state: OrderState): any => {
      return state.paymentMethods.results;
    },
    seasons: (state: OrderState): any => {
      return state.seasons.results;
    },
    paymentsVersionsList: (state: OrderState): any => {
      return state.paymentsVersions;
    },
    versionsList: (state: OrderState): any => {
      return state.versions;
    },
    count: (state: OrderState): number => {
      return state.count;
    },
  },
  mutations: {
    saveOrdersList: (state: OrderState, list: any): void => {
      state.orders = list;
    },
    savePaymentMethods: (state: OrderState, list: any): void => {
      state.paymentMethods = list;
    },
    saveSeasons: (state: OrderState, list: any): void => {
      state.seasons = list;
    },
    extendsPaymentMethodsList: (state: OrderState, obj: any): void => {
      state.paymentMethods.results = state.paymentMethods.results.concat(obj);
    },
    saveVersions: (state: OrderState, list: any): void => {
      state.versions = list;
    },
    savePaymentsVersions: (state: OrderState, list: any): void => {
      state.paymentsVersions = list;
    },
    saveOrdersCount: (state: OrderState, count: number): void => {
      state.count = count;
    },
    saveFilteredOrdersList: (state: OrderState, filteredOrders: Order[]) => {
      state.filteredOrdersList = filteredOrders;
    },
  },
  actions: {
    // Search into the API the list of orders. Use `Authorization` to see that
    // list.
    async getOrdersList(context: OrderContext, limit: number | undefined) {
      const api = context.rootState.api;

      let path = `${api}/orders/`;
      if (limit) {
        path += "?limit=" + limit;
      }

      await fetch(path, {
        headers: {
          Authorization: `Bearer ${context.rootGetters["auth/accessToken"]}`,
        },
      }).then(async (response) => {
        context.commit("saveOrdersList", await response.json());
      });
    },
    // Find an order by its `id`
    async findOrder(context: OrderContext, id: number): Promise<any> {
      const api = context.rootState.api;
      let result = {
        data: {},
        status: 404,
      };

      await fetch(`${api}/orders/${id}/`, {
        headers: {
          Authorization: `Bearer ${context.rootGetters["auth/accessToken"]}`,
        },
      }).then(async (response) => {
        result = {
          data: await response.json(),
          status: response.status,
        };
      });

      return result;
    },
    // Get list of available payment methods
    async getPaymentMethods(context: OrderContext, limit: number | undefined) {
      const api = context.rootState.api;

      let path = `${api}/orders/payment-methods/`;
      if (limit) {
        path += "?limit=" + limit;
      }

      await fetch(path, {
        headers: {
          Authorization: `Bearer ${context.rootGetters["auth/accessToken"]}`,
        },
      }).then(async (response) => {
        context.commit("savePaymentMethods", await response.json());
      });
    },
    // Add new payment
    async newPayment(context: OrderContext, data: any): Promise<number> {
      const api = context.rootState.api;
      context.commit("changeLoadingStatus", true, { root: true });
      let status = 400;

      await fetch(`${api}/orders/${data.order}/payments/`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${context.rootGetters["auth/accessToken"]}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }).then(async (response) => {
        status = response.status;
        const data = await response.json();
        if (status == 201) {
          context.dispatch(
            "toast",
            {
              message: "Modifiche salvate",
              type: "success",
            },
            { root: true }
          );
        } else {
          context.dispatch(
            "toast",
            {
              message: data["detail"] ?? data,
              type: "error",
            },
            { root: true }
          );
        }
      });

      context.commit("changeLoadingStatus", false, { root: true });
      return status;
    },
    // Edit a payment
    async editPayment(context: OrderContext, data: any): Promise<number> {
      const api = context.rootState.api;
      context.commit("changeLoadingStatus", true, { root: true });
      let status = 400;

      await fetch(`${api}/orders/${data.order}/payments/${data.id}/`, {
        method: "PATCH",
        headers: {
          Authorization: `Bearer ${context.rootGetters["auth/accessToken"]}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }).then(async (response) => {
        status = response.status;
        if (status == 200) {
          context.dispatch(
            "toast",
            {
              message: "Modifiche salvate",
              type: "success",
            },
            { root: true }
          );
        } else {
          context.dispatch(
            "toast",
            {
              message: await response.json(),
              type: "error",
            },
            { root: true }
          );
        }
      });

      context.commit("changeLoadingStatus", false, { root: true });
      return status;
    },
    // Get versions list from an order `id`
    async getVersions(context: OrderContext, id: number) {
      const api = context.rootState.api;
      const path = `${api}/orders/${id}/versions/`;
      context
        .dispatch("revisions/getVersions", path, { root: true })
        .then((result: any) => {
          context.commit("saveVersions", result);
        });
    },
    // Get versions payment list from an order `id`
    async getPaymentsVersions(context: OrderContext, id: number) {
      const api = context.rootState.api;
      const path = `${api}/orders/${id}/payments/versions/`;
      context
        .dispatch("revisions/getVersions", path, { root: true })
        .then((result: any) => {
          context.commit("savePaymentsVersions", result);
        });
    },
    // Add new payment-method
    async newPaymentMethod(context: OrderContext, data: any) {
      const api = context.rootState.api;
      context.commit("changeLoadingStatus", true, { root: true });

      await fetch(`${api}/orders/payment-methods/`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${context.rootGetters["auth/accessToken"]}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }).then(async (response) => {
        const status = response.status;
        if (status == 201) {
          context.dispatch(
            "toast",
            {
              message: "Modifiche salvate",
              type: "success",
            },
            { root: true }
          );
          context.commit("extendsPaymentMethodsList", await response.json());
        } else {
          context.dispatch(
            "toast",
            {
              message: await response.json(),
              type: "error",
            },
            { root: true }
          );
        }
      });

      context.commit("changeLoadingStatus", false, { root: true });
    },
    // Search a payment-method into the array and delete it
    async deletePaymentMethod(context: OrderContext, index: number) {
      const api = context.rootState.api;
      context.commit("changeLoadingStatus", true, { root: true });

      const id = context.state.paymentMethods.results[index].id;

      await fetch(`${api}/orders/payment-methods/${id}`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${context.rootGetters["auth/accessToken"]}`,
        },
      }).then(async (response) => {
        const status = response.status;
        if (status == 204) {
          context.dispatch(
            "toast",
            {
              message: "Metodo di pagamento rimosso",
              type: "success",
            },
            { root: true }
          );
          context.state.paymentMethods.results.splice(index, 1);
        } else {
          context.dispatch(
            "toast",
            {
              message: "Metodo di pagamento non rimosso",
              type: "error",
            },
            { root: true }
          );
        }
      });

      context.commit("changeLoadingStatus", false, { root: true });
    },
    // Edit a payment-method
    async editPaymentMethod(context: OrderContext, data: any) {
      const api = context.rootState.api;
      context.commit("changeLoadingStatus", true, { root: true });

      await fetch(`${api}/orders/payment-methods/${data.id}`, {
        method: "PATCH",
        headers: {
          Authorization: `Bearer ${context.rootGetters["auth/accessToken"]}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }).then(async (response) => {
        const status = response.status;
        if (status == 200) {
          context.dispatch(
            "toast",
            {
              message: "Modifiche salvate",
              type: "success",
            },
            { root: true }
          );
        } else {
          context.dispatch(
            "toast",
            {
              message: "Modifiche non salvate",
              type: "error",
            },
            { root: true }
          );
        }
      });

      context.commit("changeLoadingStatus", false, { root: true });
    },
    // Create a new order
    async newOrder(context: OrderContext, data: any): Promise<any> {
      const api = context.rootState.api;

      context.commit("changeLoadingStatus", true, { root: true });

      let res: any;

      await fetch(`${api}/orders/`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${context.rootGetters["auth/accessToken"]}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }).then(async (response) => {
        const status = response.status;
        res = await response.json();
        if (status == 201) {
          context.dispatch(
            "toast",
            {
              message: "Modifiche salvate",
              type: "success",
            },
            { root: true }
          );
        } else {
          context.dispatch(
            "toast",
            {
              message: res,
              type: "error",
            },
            { root: true }
          );
        }
      });

      context.commit("changeLoadingStatus", false, { root: true });

      return res;
    },
    // Edit an order. In `data` we have an object got from `findOrder`
    async editOrder(context: OrderContext, data: any): Promise<number> {
      const api = context.rootState.api;

      context.commit("changeLoadingStatus", true, { root: true });
      let status = 400;
      let res: any;

      await fetch(`${api}/orders/${data.id}/`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${context.rootGetters["auth/accessToken"]}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }).then(async (response) => {
        status = response.status;
        res = await response.json();
        if (status == 200) {
          context.dispatch(
            "toast",
            {
              message: "Modifiche salvate",
              type: "success",
            },
            { root: true }
          );
        } else {
          context.dispatch(
            "toast",
            {
              message: res,
              type: "error",
            },
            { root: true }
          );
        }
      });

      context.commit("changeLoadingStatus", false, { root: true });

      return status;
    },

    applyFilters({ commit, state }: OrderContext, filters: Filters) {
      let filteredOrders = state.orders.results; // Supponiamo che state.orders.results sia un array
      // Applica filtro per cliente
      if (filters.client) {
        filteredOrders = filteredOrders.filter(
          (order: Order) => order.client.id === filters.client
        );
      }
      // Applica filtro per data
      if (filters.date) {
        filteredOrders = filteredOrders.filter(
          (order: Order) => order.date === filters.date
        );
      }
      // Applica filtro per agente (commercial)
      if (filters.commercial) {
        filteredOrders = filteredOrders.filter(
          (order: Order) => order.commercial.id === filters.commercial
        );
      }
      // Applica filtro per lo stato di completamento
      if (filters.completed !== null) {
        filteredOrders = filteredOrders.filter(
          (order: Order) => order.is_completed === filters.completed
        );
      }
      // Commit della lista filtrata
      commit("saveFilteredOrdersList", filteredOrders);
    },

    // Make the call to print an order as PDF file. Use `id` and `fields` as
    // parameters on GET
    async print(context: OrderContext, data: any) {
      const api = context.rootState.api;
      context.commit("changeLoadingStatus", true, { root: true });
      let path = `${api}/orders/${data.id}/print/?`;

      for (const field of data.fields) path += field + "&";

      const pdfError = () => {
        context.dispatch(
          "toast",
          {
            message: "Errore nella generazione del PDF",
            type: "error",
          },
          { root: true }
        );
      };

      fetch(path, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${context.rootGetters["auth/accessToken"]}`,
        },
      })
        .then(async (r) => ({
          filename: `order-${data.id}.pdf`,
          blob: await r.blob(),
          headers: r.headers,
          status: r.status,
        }))
        .then((obj) => {
          if (obj.status != 200) {
            pdfError();
            return;
          }
          const newBlob = new Blob([obj.blob], { type: "application/pdf" });

          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(newBlob);
          } else {
            // For other browsers: create a link pointing to the ObjectURL containing the blob.
            const objUrl = window.URL.createObjectURL(newBlob);

            const link = document.createElement("a");
            link.href = objUrl;
            link.download = obj.filename;
            link.click();

            // For Firefox it is necessary to delay revoking the ObjectURL.
            setTimeout(() => {
              window.URL.revokeObjectURL(objUrl);
            }, 250);
          }
        })
        .catch(() => {
          pdfError();
        })
        .finally(() => {
          context.commit("changeLoadingStatus", false, { root: true });
        });
    },
    // Get list of seasons
    async getSeasons(context: OrderContext) {
      const api = context.rootState.api;

      await fetch(`${api}/orders/seasons/`, {
        headers: {
          Authorization: `Bearer ${context.rootGetters["auth/accessToken"]}`,
        },
      }).then(async (response) => {
        context.commit("saveSeasons", await response.json());
      });
    },
    // Search orders by its client
    async searchOrders(context: OrderContext, client: number): Promise<any> {
      const api = context.rootState.api;
      let result: any = [];
      let next = 1;
      let offset = 0;

      const path = `${api}/orders/search/?limit=20`;
      while (next) {
        await fetch(`${path}&offset=${offset}`, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${context.rootGetters["auth/accessToken"]}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ client }),
        }).then(async (response) => {
          const data = await response.json();
          result = result.concat(data.results);
          next = data.next;
          offset += data.results.length;
        });
      }

      return result;
    },
  },
};

export default orders;
